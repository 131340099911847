import { IUseScroll } from '../models/composables/useScroll.model';

const defaultInitNumberOfItems = 5;
const defaultNumberHiddenItemsWithoutScroll = 3;

export function useScroll(
  initNumberHiddenItems: number, // начально кол-во скрытых элементов
  initNumberOfItems = defaultInitNumberOfItems, // начальное кол-во отображаемых элементов
  numberHiddenItemsWithoutScroll = defaultNumberHiddenItemsWithoutScroll, // начально кол-во элементов после добавления которых не нужна прокрутка
): IUseScroll {
  const numberItems = ref(0);
  const numberDisplayItems = ref(initNumberOfItems);
  const numberHiddenItems = ref(initNumberHiddenItems);
  const displayScroll = ref(false);

  const needDisplaySearch = computed<boolean>(() => numberItems.value > numberHiddenItemsWithoutScroll + initNumberOfItems);

  function calcMoreItems(newNumberItems: number): void {
    if (displayScroll.value) {
      numberDisplayItems.value += newNumberItems;
    } else {
      const diff = newNumberItems - initNumberOfItems;
      numberHiddenItems.value = diff > 0 ? diff : 0;
    }
  }

  function onDisplayMoreItems(): void {
    calcDisplayItems();
    if (numberHiddenItems.value > numberHiddenItemsWithoutScroll) {
      displayScroll.value = true;
    }
    clearHiddenItems();
  }

  function onResetState(): void {
    displayScroll.value = false;
    numberDisplayItems.value = initNumberOfItems;
    numberHiddenItems.value = initNumberHiddenItems;
    calcMoreItems(numberItems.value);
  }

  function calcDisplayItems(): void {
    numberDisplayItems.value += numberHiddenItems.value;
  }

  function clearHiddenItems() {
    numberHiddenItems.value = 0;
  }

  function setNumberItems(newNumberItems): void {
    numberItems.value = newNumberItems;
  }

  if (process.client) {
    watch(
      () => numberItems.value,
      (newNumberItems) => calcMoreItems(newNumberItems),
    );
  }

  onMounted(() => calcMoreItems(numberItems.value));

  return {
    numberDisplayItems,
    numberHiddenItems,
    displayScroll,
    needDisplaySearch,

    calcMoreItems,
    onDisplayMoreItems,
    setNumberItems,
    onResetState,
  };
}
